import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import MainWrapper from './layout/MainWrapper';
import axios from 'axios';
import { apiRoot } from '../services/helpers';
import { RichTextEditor } from '@mantine/rte';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import Dropzone, {useDropzone} from 'react-dropzone';
import StyledDropZone from '../components/StyledDropZone';

const _ = require('lodash');

const NewsForm = ( props:any ) => {
    const { auth, channels } = props;

    const params:any = useParams();
    const newsId:any = params.id === 'new' ? 'new' : parseInt(params.id);
    const showNewOk:any = params.msg === '1';
    const navigate = useNavigate();

    const blankNews:any = {
        headline: '',
        subhead: '',
        author: '',
        extUrl: '',
        channel_id: 1,
        feed: "1",
        image: 'https://pennhillspassport.com/news/default-image.jpg',
    }

    const initNews:any = {};
    const initFile:File = new File([""], "filename");
    const [newsLoaded, setNewsLoaded] = useState( -1 );
    const [news, setNews] = useState( blankNews );
    const [rteContent, setRteContent] = useState( newsId === 'new' ? 'Enter your story here.' : 'not loaded' );
    const [newsDate, setNewsDate] = useState( new Date() );
    const [imageFile, setImageFile] = useState( initFile );
    const [hideNewOk, sethideNewOk] = useState( false );

    const getNews = async () => {
        const res: any = await axios.get(apiRoot() + '/news/story/'+newsId );
        if (res.data.success) {
            setNewsLoaded(1);
            setNews(res.data.data);
            console.log(res.data.data);
            setRteContent( res.data.data.body );
            const setDate = new Date( res.data.data.datePublished );
            setNewsDate( setDate );
        } else {
            setNewsLoaded(0);
        }
    }

    const handleImageUpload = useCallback( (file: File): Promise<string> =>
        new Promise((resolve:any, reject:any) => {
            const formData = new FormData();
            formData.append('image', file);

            fetch('https://api.imgbb.com/1/upload?key=63d6e4967b43139d8dcf1dd03ad7b47a', {
                method: 'POST',
                body: formData,
            })
                .then((response:any) => response.json())
                .then((result:any) => resolve(result.data.url))
                .catch(() => reject(new Error('Upload failed')));
        }), []
    );

    const setNewsField = ( field:string, value:any ) => {
        const tmpNews:any = { ...news };
        tmpNews[field] = value;
        setNews( tmpNews );
    }

    const submitNewsStory = async () => {
        try {
            let data = _.cloneDeep( news );
            data.body = rteContent;
            data.datePublished =  new Date(newsDate).toISOString();
            data.userId = auth.user_id;
            data.channelId = news.channel_id;
            data.feed = news.feed;

            const res = newsId === 'new' ? await axios.post( apiRoot()+'/news/story', data )
                                         : await axios.put( apiRoot()+'/news/story', data );
            if (res.data.success) {
                if (newsId === 'new') {
                    //navigate("/news/"+res.data.id+'/'+'1');
                    window.location.href = '/news/'+res.data.id+"/1";
                }
            }
        } catch(err) {
            console.log('error', err);
        }
    }

    if (newsLoaded === -1 && newsId !== 'new') {
        getNews();
    }

    // Handle an image upload
    useEffect(() => {
        (async () => {
            if (imageFile && imageFile.name  && imageFile.name !== 'filename') {
                try {
                    const formData = new FormData();
                    formData.append('image', imageFile)
                    const res = await axios.post( apiRoot()+'/news/image', formData,{
                        headers: { 'content-type': 'multipart/form-data'  }
                    });
                    if (res.data.success) {
                        const tmpNews:any = { ...news };
                        tmpNews.image = res.data.url;
                        setNews( tmpNews );
                        setImageFile( initFile );
                    }
                } catch(err) {
                    console.log('error', err);
                }
            }
        })();
    }, [imageFile]);

    return (
        <MainWrapper>
            <div className="container">
                <div className="row">
                    <div className={"col-12"}>
                        <h3 className={'subtitle'}>
                            <button className="btn btn-success btn-sm" onClick={()=>submitNewsStory()}>Save Changes</button>
                            { newsId === 'new' ? 'Create News Story' : 'Edit News Story'}
                        </h3>
                        <hr/>
                        { showNewOk && !hideNewOk ?
                            <div className={'alert alert-success'}>
                                <div style={{float:'right'}} onClick={()=>sethideNewOk(true)}><i className={'fas fa-times'}></i> </div>
                                <b>Your story has been created successfully! You can continue to edit it below.</b>
                            </div>
                            : <></>
                        }
                        <br/>
                        {(newsLoaded === 1 && newsId !== 'new') || newsId === 'new' ?
                            <div className="row">
                                <div className={"col-6"}>
                                    <div className="form-group">
                                        <label style={{ display: 'block' }}>Publish Date</label>
                                        <DatePicker selected={newsDate}
                                                    className="form-control"
                                                    onChange={(date: any) => setNewsDate(date)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Headline</label>
                                        <input type="text" className="form-control" value={news.headline}
                                               onChange={(e: any) => setNewsField('headline', e.target.value)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Sub-Heading</label>
                                        <input type="text" className="form-control" value={news.subhead}
                                               onChange={(e: any) => setNewsField('subhead', e.target.value)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Author</label>
                                        <input type="text" className="form-control" value={news.author}
                                               onChange={(e: any) => setNewsField('author', e.target.value)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>External URL</label>
                                        <input type="text" className="form-control" value={news.extUrl}
                                               onChange={(e: any) => setNewsField('extUrl', e.target.value)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Feed</label>
                                        <select className="form-control" value={news.feed}
                                                onChange={(e: any) => setNewsField('feed', e.target.value)}>
                                              <option value={'1'}>News Feed</option>
                                              <option value={'2'}>Must Know Info</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Channel</label>
                                        <select className="form-control" value={news.channel_id}
                                                onChange={(e: any) => setNewsField('channel_id', e.target.value)}>
                                            {channels.map((c: any) => c.calendar_only === "0" &&
                                              <option value={c.id} key={'chan-' + c.id}>{c.name}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Image</label>
                                        <p><i>Click or Drag & Drop...<br/>Images should have a landscape orientation and
                                            must be a JPG or PNG image.</i></p>
                                        <StyledDropZone setImageFile={setImageFile} imageUrl={news.image}/>
                                    </div>
                                </div>
                                <div className={"col-6"}>
                                    {rteContent !== 'not loaded' &&
                                      <RichTextEditor value={rteContent} onChange={setRteContent}
                                                      onImageUpload={handleImageUpload}
                                                      controls={[
                                                          ['bold', 'italic', 'underline', 'strike'],
                                                          ['h1', 'h2', 'h3'],
                                                          ['unorderedList', 'orderedList'],
                                                          ['link', 'image', 'video', 'blockquote'],
                                                          ['alignLeft', 'alignCenter'], ['clean']
                                                      ]}
                                      />
                                    }
                                </div>
                            </div>
                            : <></>
                        }
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-12">
                        <button className="btn btn-success btn-md"  onClick={()=>submitNewsStory()}>Save Changes</button>
                    </div>
                </div>
            </div>
        </MainWrapper>
    )
}

const mapStateToProps = ( state:any ) => ({
    auth: state.auth,
    channels: state.auth.channels
});

export default connect(mapStateToProps, { })(NewsForm);

