import React, { useState, useEffect, useRef, useCallback } from 'react';

import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import MainWrapper from './layout/MainWrapper';
import axios from 'axios';
import { apiRoot, wait } from '../services/helpers';
import Editor from '@monaco-editor/react';
import StyledDropZone from '../components/StyledDropZone';
import { RichTextEditor } from '@mantine/rte';
import { blankCommDirEntry } from '../utils/models';

const _ = require('lodash');

const Directories = ( props:any ) => {
    const { } = props;

    const params:any = useParams();
    const dirId:any = params.dId;

    const initArr:any = [];
    const initObj:any = {};
    const initFile:File = new File([""], "filename");
    const [dirLoaded, setDirLoaded] = useState( -1 );
    const [categories, setCategories] = useState( initArr );
    const [dirData, setDirData] = useState( initArr );
    const [activeCat, setActiveCat] = useState( '' );
    const [modalOpen, setmodalOpen] = useState( false );
    const [editEntry, setEditEntry] = useState( initObj );
    const [editEntryField, setEditEntryField] = useState( '' );
    const [imageFile, setImageFile] = useState( initFile );
    const [thinking, setThinking] = useState( false );
    const [entryPhotos, setEntryPhotos] = useState( initArr );
    const [rteContent, setRteContent] = useState( '' );
    const [createEntry, setCreateEntry] = useState( false );

    const getCategories = async () => {
        const res: any = await axios.get(apiRoot() + '/directory/categories/'+dirId );
        if (res.data.success) {
            setCategories(res.data.categories);
            setActiveCat( res.data.categories[0].slug );
        }
    }

    const getDirList = async () => {
        await getCategories();
        const res: any = await axios.get(apiRoot() + '/directory/structure/'+dirId );
        if (res.data.success) {
            setDirLoaded( 1 );
            setDirData( res.data.data.records );
        }
    }

    const deleteEntry = async ( dirId:string ) => {
        const yn = window.confirm("Are you sure you want to delete this entry?");
        if (yn) {
            const res = await axios.delete(apiRoot()+'/directory/entry/'+dirId);
            if (res.data.success) {
                await getDirList();
            }
        }
    }

    const getPhotos = async (entryId: string) => {
        setThinking(true);
        try {
            const res: any = await axios.get(apiRoot() + '/directory/images/' + entryId);
            setEntryPhotos(res.data.images);
        } catch (err) {
            console.log('error', err);
        }
        setThinking(false);
    }

    const deleteImage = async (imageId: string) => {
        const yn = window.confirm('Are you sure you want to delete this photo?');
        if (yn) {
            setThinking(true);
            try {
                const res: any = await axios.delete(apiRoot() + '/directory/images/' + imageId);
                await getPhotos(editEntry.deId);
            } catch (err) {
                console.log('error', err);
            }
            setThinking(false);
        }
    }

    const onCreate = () => {
        setCreateEntry( true );
        setmodalOpen( true );
        setEditEntryField('');
        setEditEntry( blankCommDirEntry );
        setRteContent( "" );
        setEntryPhotos( initArr );
    }

    const onEditEntry = async ( entryId:string ) => {
        setCreateEntry( false );
        let entry = _.cloneDeep(dirData);
        entry = entry.filter( (dd:any) => dd.deId === entryId ).pop();
        entry.dePlaceData = JSON.parse(entry?.dePlaceData);
        if (entry.dePlaceData.hasOwnProperty('result')) {
            entry.dePlaceData = entry.dePlaceData.result;
        }
        entry.deData = JSON.parse(entry?.deData);
        setmodalOpen( true );
        setEditEntryField('');
        setEditEntry( entry );
        console.log(entry);
        setRteContent( entry.deDescription );
        await getPhotos( entryId );
    }

    const onDataChange = ( key:string, value:any ) => {
        setEditEntryField(key);
        const tmpEdit = _.cloneDeep( editEntry );
        if (tmpEdit[key] !== value) {
            tmpEdit[key] = key === 'dePlaceData' || key === 'deData' ? JSON.parse(value) : value;
            setEditEntry(tmpEdit);
        }
    }

    const onCloseModal = () => {
        setmodalOpen( false );
        setEditEntryField( '' );
        setEditEntry( initObj );
        setRteContent( '' );
    }

    const refreshEntry = async (pid: string) => {
        const yn = window.confirm("Are you sure you want to update this place data?");
        if (yn) {
            try {
                const updData = { place_id: pid, mode: 'upd' };
                const upd: any = await axios.post(apiRoot() + '/directory/entry', updData);
                if (upd.data.success) {
                    await getDirList();
                }
            } catch (err) {
                console.log('error', err);
            }
        }
    }

    const onSaveEntry = async () => {
        console.log('saving data', editEntry);
        const data = {
            directory_id: 2,
            directory_category_id: editEntry.dcId,
            place_id: Date.now(),
            name: editEntry.deName,
            description: editEntry.deDescription,
            phone: editEntry.dePhone,
            website: editEntry.deWebsite,
            lat: editEntry.deLat,
            lng: editEntry.deLng,
            data: {},
            placedata: editEntry.dePlaceData,
            mode: 'ins'
        }
        try {
            const ins:any = await axios.post( apiRoot()+'/directory/entry', data );
            if (ins.data.success) {
                await getDirList();
                await onEditEntry( ins.data.newId );
            }
            console.log('ins result', ins);
        }
        catch(err) {
            console.log('error on insert', err);
        }
    }

    useEffect(() => {
        if (dirLoaded === -1) {
            (async () => {  await getDirList();  })();
        }
    }, [dirLoaded]);

    // AUTO SAVE DATA CHANGES ONLY ON EDIT
    useEffect( () => {
        const doUpdate = async ( ) => {
            if (modalOpen && editEntryField !== '' && editEntry?.deId) {
                console.log('editEntry', editEntry.deId, editEntryField, modalOpen, editEntry);
                setThinking( true );
                await wait(200);
                const data = {
                    id: editEntry.deId,
                    field: editEntryField,
                    value: editEntry[editEntryField]
                }
                console.log('put', data);
                const res = axios.put( apiRoot()+'/directory/entry', data);
                setEditEntryField('');
                setThinking( false );
            }
        }
        const delayDebounceFn = setTimeout(() => doUpdate(), 500);
        return () => clearTimeout(delayDebounceFn);

    },[editEntry]);

    useEffect( () => {
        onDataChange('deDescription', rteContent);
    }, [rteContent]);

    // Handle an image upload
    useEffect(() => {
        (async () => {
            if (imageFile && imageFile.name  && imageFile.name !== 'filename') {
                try {
                    setThinking(true);
                    const formData = new FormData();
                    formData.append('image', imageFile)
                    const res = await axios.post( apiRoot()+'/directory/image/'+editEntry.deId, formData,{
                        headers: { 'content-type': 'multipart/form-data'  }
                    });
                    if (res.data.success) {
                        // add new image to entry
                        getPhotos( editEntry.deId );
                    }
                    setImageFile( initFile );
                    setThinking(false);
                } catch(err) {
                    console.log('error', err);
                    setThinking(false);
                }
            }
        })();
    }, [imageFile]);

    return (
        <>
        <MainWrapper>
            <div className="container">
                <div className="row">
                    <div className={"col-12"}>
                        <h3 className={'subtitle'}>
                            <Link to={'/directories/new'} className="btn btn-success">Entry Finder</Link>
                            <button className="btn btn-success" onClick={()=>onCreate()}>Create Entry</button>
                            Directories</h3>
                        <hr/>

                        { dirLoaded===1 && categories.length ?
                            <ul className="nav nav-pills">
                                {categories.map((cat: any) =>
                                    <li className="nav-item" key={'catt-'+cat.id} onClick={()=>setActiveCat(cat.slug)}>
                                        <a className={"nav-link" + (activeCat===cat.slug ? " active" : "")}>{cat.name}</a>
                                    </li>
                                )}
                            </ul>
                            :
                            <></>
                        }

                        { dirLoaded===1 && categories.length ?
                            <div>
                                {categories.map((cat: any) =>
                                    <div className={ cat.slug === activeCat ? '' : 'hidden'} key={'caty-'+cat.id}>
                                        <table className='table'>
                                            <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Name</th>
                                                <th>Phone</th>
                                                <th>Website</th>
                                                <th>Photos</th>
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            { dirData.map( (dd:any) => {
                                                if (dd.dcSlug === cat.slug) {
                                                    const placeData = JSON.parse(dd.dePlaceData);
                                                    return (
                                                        <tr key={'row-'+dd.deId}>
                                                            <td>{dd.deId}</td>
                                                            <td>{dd.deName}</td>
                                                            <td>{placeData?.formatted_phone_number}</td>
                                                            <td><span className="truncate"><a href={placeData?.website} target="_blank">{placeData?.website}</a></span></td>
                                                            <td>{dd.dpCount}</td>
                                                            <td className={'no-wrap'}>
                                                                <button className="btn btn-info btn-sm" onClick={()=>onEditEntry(dd.deId)}><i className="fas fa-pencil"></i></button>
                                                                &nbsp;&nbsp;
                                                                <button className="btn btn-success btn-sm" onClick={()=>refreshEntry(dd.dePlaceId)}><i className="fas fa-sync"></i></button>
                                                                &nbsp;&nbsp;
                                                                <button className="btn btn-danger btn-sm" onClick={()=>deleteEntry(dd.deId)}><i className="fas fa-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </MainWrapper>
            <div className={'wb-modal' + (modalOpen ? ' open' : '')}>
                <div className={'wb-modal-wrap'}>
                    <div className={'wb-modal-body'}>
                        <h3>{ createEntry ? "Create New Entry" : "Editing: " + editEntry.deName } &nbsp; { thinking ? <span style={{color:'#009900'}}><i className="fas fa-spinner fa-spin"></i></span> : <span></span> }</h3>
                        <div className={'wb-modal-content'}>
                            <div className="container">
                                <div className="row">
                                    <div className={ createEntry ? "col-5 scroll-vert" : "col-4 scroll-vert"} >
                                        <div className={'form-group'}>
                                            <label>Name:</label>
                                            <input type={'text'} className={'form-control'} value={editEntry.deName} onChange={(e:any)=>onDataChange('deName', e.target.value)}/>
                                        </div>
                                        <div className={'form-group'}>
                                            <label>Category:</label>
                                            <select className={'form-control'} value={editEntry.dcId} onChange={(e:any)=>onDataChange('dcId', e.target.value)}>
                                                <option value={0}>-- SELECT CATEGORY ---</option>
                                                { categories.map( (cat:any) =>
                                                    <option value={cat.id} key={'catx-'+cat.id}>{cat.name}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className={'form-group'}>
                                            <label>Phone:</label>
                                            <input type={'text'} className={'form-control'} onChange={(e:any)=>onDataChange('dePhone', e.target.value)} value={editEntry.dePhone?.length ? editEntry.dePhone : editEntry.dePlaceData?.formatted_phone_number} />
                                        </div>
                                        <div className={'form-group'}>
                                            <label>URL:</label>
                                            <input type={'text'} className={'form-control'} onChange={(e:any)=>onDataChange('deWebsite', e.target.value)} value={editEntry.deWebsite?.length ? editEntry.deWebsite : editEntry.dePlaceData?.website} />
                                        </div>
                                        <div className={'form-group'}>
                                            <label>Lat:</label>
                                            <input type={'text'} className={'form-control'} onChange={(e:any)=>onDataChange('deLat', e.target.value)} value={editEntry.deLat?.length ? editEntry.deLat : editEntry.dePlaceData?.geometry?.location?.lat} />
                                        </div>
                                        <div className={'form-group'}>
                                            <label>Lng:</label>
                                            <input type={'text'} className={'form-control'} onChange={(e:any)=>onDataChange('deLng', e.target.value)} value={editEntry.deLng?.length ? editEntry.deLng : editEntry.dePlaceData?.geometry?.location?.lng} />
                                        </div>
                                        {/*<div className={'form-group'}>*/}
                                        {/*    <label>Description:</label>*/}
                                        {/*    <textarea className={'form-control'} onChange={(e:any)=>onDataChange('deDescription', e.target.value)} value={editEntry.deDescription} style={{minHeight:'150px'}} />*/}
                                        {/*</div>*/}
                                        <div className={'form-group'}>
                                            <RichTextEditor value={rteContent} onChange={setRteContent}
                                                            controls={[
                                                                ['bold', 'italic', 'underline', 'strike'],
                                                                ['h1', 'h2', 'h3'],
                                                                ['unorderedList', 'orderedList'],
                                                                ['link', 'blockquote'], ['clean']
                                                            ]}
                                            />
                                        </div>
                                        { createEntry ?
                                            <div className={'form-group'}>
                                                <button className={'btn btn-lg btn-success'} onClick={()=>onSaveEntry()}>Save New Entry</button>
                                            </div>
                                            : <></>
                                        }
                                    </div>
                                    <div className={createEntry ? "col-7 scroll-vert" : "col-6 scroll-vert"}>
                                        <div className={'form-group'}>
                                            <label>Place Data:</label>
                                            <Editor height="350px"
                                                    theme="light"
                                                    language="JSON"
                                                    value={JSON.stringify(editEntry.dePlaceData, null, 2)}
                                                    onChange={(value, ev)=>onDataChange('dePlaceData', value)} />
                                        </div>
                                        <div className={'form-group'}>
                                            <label>Maps Data:</label>
                                            <Editor height="350px"
                                                    theme="light"
                                                    language="JSON"
                                                    value={JSON.stringify(editEntry.deData, null, 2)}
                                                    onChange={(value, ev)=>onDataChange('deData', value)} />
                                        </div>
                                    </div>
                                    { !createEntry ?
                                        <>
                                            <div className="col-2 scroll-vert">
                                                <div className={'form-group'}>
                                                    <label>Photos:</label>
                                                    <div className={'dir-image'}><StyledDropZone setImageFile={setImageFile} imageUrl={'https://pennhillspassport.com/img/dropzone.png'} showPreview={false} /></div>
                                                    {entryPhotos.map( (p:any) =>
                                                        <div className={'dir-image'} key={'image-'+p.id}>
                                                            <img src={p.url} style={{maxWidth:'100%'}}/>
                                                            <div className={'del-image'} onClick={()=>deleteImage(p.id)}>
                                                                <i className={'fas fa-circle'}></i>
                                                                <i className={'fas fa-times-circle'}></i>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                        : <></>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'wb-modal-footer'}>
                        </div>
                        <div className={'wb-modal-close'} onClick={()=>onCloseModal()}>
                            <i className={'fas fa-circle'}></i>
                            <i className={'fas fa-times-circle'}></i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(Directories);
